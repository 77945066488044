define("discourse/plugins/discourse-topic-management/discourse/initializers/add-trash-button", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/url"], function (_exports, _pluginApi, _ajax, _ajaxError, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "add-trash-button",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.37.1", api => {
        const siteSettings = api.container.lookup("site-settings:main");
        const categories = siteSettings.discourse_topic_management_categories_remove_button_visible.split("|");
        api.addPostMenuButton("trash", post => {
          if (siteSettings.discourse_topic_management_hidden_category_id !== "" && categories.includes(post.topic.category_id.toString()) && post.post_number === 1 && post.topicCreatedById === api.getCurrentUser().id) {
            return {
              action: "sendTopicToRecycleBin",
              icon: "box-archive",
              className: "topic-management-button",
              title: "discourse_topic_management.topic.title"
            };
          }
        });
        api.attachWidgetAction("post", "sendTopicToRecycleBin", function () {
          const topicId = this.attrs.topicId;
          const categoryId = this.attrs.topic.category_id;
          this.dialog.yesNoConfirm({
            message: I18n.t("discourse_topic_management.topic.delete_confirm"),
            didConfirm: () => {
              (0, _ajax.ajax)(`/move_topic_to_hidden_category`, {
                method: "POST",
                data: {
                  topic_id: topicId
                }
              }).then(() => {
                _url.default.routeTo(`/c/${categoryId}`);
              }).catch(error => {
                (0, _ajaxError.popupAjaxError)(error);
              });
            }
          });
        });
      });
    }
  };
});